<template>
  <v-container
    fluid
    class="pa-2 daytime-advice-search-view"
  >
    <v-row class="pa-4 pt-6 pb-0">
      <v-col
        cols="3"
        sm="3"
        md="3"
        class="pt-0"
      >
        <daytime-advice-sheet-addon
          v-if="daytimeAdviceSheet.userId != null"
          ref="addon"
          :active="editing"
          :user-id="daytimeAdviceSheet.userId"
        />
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="6"
        class="pt-0"
      >
        <v-row
          v-if="!editing"
          class="px-0 pb-0 pt-3 align-end flex-column"
        >
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="pl-0 pt-0 justify-end"
          >
            <v-btn
              color="pink"
              class="font-weight-bold"
              block
              theme="dark"
              @click="startEditing()"
            >
              編集する
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="editing"
          class="px-0 pb-0 pt-3"
        >
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="pt-0"
          >
            <v-btn
              variant="outlined"
              color="pink"
              block
              @click="cancel()"
            >
              キャンセル
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="pt-0"
          >
            <v-btn
              color="pink"
              class="font-weight-bold"
              block
              theme="dark"
              @click="saveSheet()"
            >
              保存する
            </v-btn>
          </v-col>
        </v-row>
        <daytime-advice-sheet-body
          :editing="editing"
        />
        <v-row>
          <v-col>
            <p class="text-md-center pink--text">
              ※ 運営からのお知らせ ※
            </p>
            <p
              style="white-space: pre-wrap; line-height: 25px"
              v-html="daytimeAdviceReferenceInfo.content"
            />
          </v-col>
        </v-row>
        <daytime-advice-images />
      </v-col>
      <v-col
        cols="3"
        sm="3"
        md="3"
        class="pa-4"
        style="height: 100%; padding-top: 65px !important;"
      >
        <span class="pa-2">
          <b>ユーザ: </b>
          <span>{{ daytimeAdviceSheet.userName }}
            (ID: {{ daytimeAdviceSheet.userId }})</span>
        </span>
        <chat-view
          :chat-id="parseInt($route.params.id)"
          log-type="daytimeAdvice"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import outcomeOptions from "../mixins/outcome_options.js";
import ChatView from "./shared/ChatView.vue";
import DaytimeAdviceSheetBody from "./shared/DaytimeAdviceSheetBody.vue";
import DaytimeAdviceSheetAddon from "./shared/DaytimeAdviceSheetAddon.vue";
import DaytimeAdviceImages from "./shared/DaytimeAdviceImages.vue";

export default {
  name: "DaytimeAdviceSearchView",
  components: {
    ChatView,
    DaytimeAdviceSheetBody,
    DaytimeAdviceSheetAddon,
    DaytimeAdviceImages
  },
  mixins: [outcomeOptions],
  data: () => ({
    editing: false,
  }),
  computed: {
    ...mapGetters([
      "daytimeAdviceSheet",
      "daytimeAdviceReferenceInfo",
    ])
  },
  watch: {
    "$route" () {
      this.clearData();
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  unmounted() {
    this.clearData();
  },
  methods: {
    ...mapActions(["updateDaytimeAdviceSheet"]),
    fetchData: function() {
      this.$store.dispatch("fetchDaytimeAdviceSheet", {
        id: this.$route.params.id
      });
      this.$store.dispatch("fetchDaytimeAdviceReferenceInfo", {
        id: this.$route.params.id
      });
    },
    clearData: function(){
      this.$store.dispatch("clearDaytimeAdviceSheet");
    },
    startEditing: function() {
      this.editing = true;
    },
    saveSheet: function() {
      this.$refs.addon.updateUser(this.$refs.addon.user);
      this.updateDaytimeAdviceSheet(this.daytimeAdviceSheet).then(() => {
        // TODO: エラーハンドリング
        this.editing = false;
      });
    },
    endEditing: function() {
      this.editing = false;
    },
    cancel: function() {
      this.endEditing();
      // 入力データをリセットする
      this.$store.dispatch("fetchDaytimeAdviceSheet", {
        id: this.$route.params.id
      });
      this.$refs.addon.findUser();
    }
  }
};
</script>
<style lang="scss">
.daytime-advice-search-view .chat-logs-view {
  height: 100%;
}
</style>
