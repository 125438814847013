import axios from "./index";

export default {
  fetchAssignedSheets(callback) {
    axios.get("/api/v1/daytime_advice_sheets/assigned").then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  fetchSheet(callback, params) {
    axios.get("/api/v1/daytime_advice_sheets/" + params.id ).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  searchSheets(callback, params) {
    axios.get("/api/v1/daytime_advice_sheets/search", {
      params: {
        q: {
          created_on_eq: params.date,
          status_eq: params.status,
          doctor_family_name_cont: params.doctorFamilyName,
          doctor_first_name_cont: params.doctorFirstName
        }
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  finishSheet(callback, params) {
    axios.post("/api/v1/daytime_advice_sheets/" + params.id + "/finish").then(response => {
      callback();
    }).catch(error => {
      console.log(error);
    });
  },
  startHandling(callback) {
    axios.post("/api/v1/daytime_advice/start_handling").then(response => {
      callback();
    }).catch(error => {
      console.log(error);
    });
  },
  finishHandling(callback, errorsCallback) {
    axios.post("/api/v1/daytime_advice/finish_handling").then(response => {
      callback();
    }).catch(error => {
      console.log(error);
      errorsCallback();
    });
  },
  updateSheet(params, successCallback, failureCallback) {
    axios.put("/api/v1/daytime_advice_sheets/" + params.id, {
      daytime_advice_sheet: {
        child_age: params.childAge,
        child_birthdate: params.childBirthdate,
        complaint: params.complaint,
        icd10_branch_id: params.branch_id,
        icd10_original_disease: params.original_disease,
        outcome: params.outcome,
        no_advice: params.noAdvice
      }
    }, {
      headers: { "Content-Type": "application/json" }
    }
    ).then(() => {
      successCallback();
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  },
  fetchActiveDoctorsWithSheets(callback) {
    axios.get("/api/v1/daytime_advice/doctors/actives").then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  fetchReferenceInfo(callback, params) {
    axios.get("/api/v1/daytime_advice/reference_info", {
      params: {
        daytime_advice_sheet_id: params.id
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  fetchDaytimeAdviceSheetStatuses(callback) {
    axios.get("/api/v1/daytime_advice_sheets/statuses").then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  fetchPoolingCount(callback) {
    axios.get("/api/v1/daytime_advice_sheets/pooling").then(response => {
      callback(response.data.count);
    }).catch(error => {
      console.log(error);
    });
  },
  fetchUserDaytimeAdviceSheets(callback, params) {
    axios.get("/api/v1/daytime_advice_sheets/users_advice_sheets", {
      params: {
        user_id: params.userId,
        exclude_sheet_id: params.excludeSheetId
      },
      cancelToken: params.cancelToken
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  fetchImages(callback, params) {
    axios.get("/api/v1/daytime_advice_sheet_images/", {
      params: {
        daytime_advice_sheet_id: params.id
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  calcChildAge(callback, params) {
    axios.get("/api/v1/daytime_advice_sheets/calc_child_age", {
      params: {
        child_birthdate: params.birthdate
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  showMedia(callback, mediaId) {
    axios.get(`/api/v1/daytime_advice_sheet_images/${mediaId}`).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
};
