<template>
  <div>
    <div
      v-if="showChatView"
      id="chat-view"
      class="chat-view-modern"
    >
      <div
        id="chat"
        class="outline"
      >
        <div
          id="chat-header"
          :class="unread ? 'new-notice' : ''"
        >
          <div
            class="chat-title"
          >
            {{ user.lineName != null ? user.lineName : user.name }} （ID: {{ user.id }}{{ couponName != null ? `/${couponName}` : '' }}）
          </div>
        </div>
        <div id="chat-body">
          <div
            id="chat-messages"
            :style="'height: calc(90vh - (' + this.baseHeaderHeight + 'px + ' + this.savedInputHeight + 'px))'"
            @click="markAsRead"
          >
            <div
              v-for="chatLog in chatLogs"
              class="chat-log"
              :class="sendByOthers(chatLog.senderType) ? 'others' : 'myself'"
            >
              <v-avatar
                v-if="sendByOthers(chatLog.senderType)"
                color="grey"
                size="40"
                class="ma-2 mt-0"
                style="align-self: start"
              >
                <v-img
                  v-if="user.lineIcon != null"
                  :src="user.lineIcon"
                ></v-img>
                <span
                  v-if="user.lineIcon == null && user.name != null"
                  class="white--text headline"
                >{{ user.name.slice(0, 1) }}</span>
                <span
                  v-if="user.name == null"
                  class="white--text headline"
                >削</span>
              </v-avatar>
              <div
                v-if="chatLog.contentType == 'text' || chatLog.contentType == 'pdf'"
                class="message-area"
              >
                <div
                  v-if="chatLog.content"
                  v-linkify:options="{ target: '_blank' }"
                  class="sent-message"
                  v-html="$sanitize(newLineCodeToBr(chatLog.content))"
                />
                <div
                  v-if="chatLog.fileUrl && chatLog.contentType == 'pdf'"
                  class="sent-file"
                >
                  <a
                    class="pink--text"
                    :href="`/api/v1/chat_logs/${chatLog.id}/media_download`"
                    target="_blank"
                  >
                    <v-icon color="pink">picture_as_pdf</v-icon>
                    {{ chatLog.fileName }}
                  </a>
                </div>
              </div>
              <div
                v-if="chatLog.contentType == 'video'"
                class="sent-image pr-2"
              >
                <video
                  :src="chatLog.content"
                  controls
                  controlsList="nodownload"
                  oncontextmenu="return false;"
                  class="filled-image"
                />
              </div>
              <div
                v-if="chatLog.contentType == 'image'"
                class="sent-image pr-2"
              >
                <img
                  v-if="chatLog.fileUrl"
                  :src="chatLog.fileUrl"
                  :alt="chatLog.fileName"
                  :title="chatLog.fileName"
                  class="filled-image secure-image"
                >
                <img
                  v-if="chatLog.content"
                  :src="chatLog.content"
                  :alt="chatLog.content"
                  :title="chatLog.content"
                  class="filled-image secure-image"
                >
              </div>
              <div class="sent-at pa-2">
                <span v-if="chatLog.sentAtDate != null">{{ chatLog.sentAtDate }} </br></span>
                <span>{{ chatLog.sentAtTime }}</span>
              </div>
            </div>
            <div
              v-if="fileUrl"
              id="fileConfirm"
              class="chat-log myself"
            >
              <div class="message-area confirm">
                <div class="confirm-message">
                  この{{ imageFile(fileType) ? '画像' : 'ファイル' }}を送信しますか?<br>(まだ送信は完了していません)
                </div>
                <div v-if="imageFile(fileType)">
                  <img
                    :src="fileUrl"
                    class="filled-image"
                  >
                  <div>{{ fileName }}</div>
                </div>
                <div v-if="pdfFile(fileType)">
                  <v-btn
                    variant="outlined"
                    color="pink-lighten-1"
                    class="text-none"
                    size="large"
                  >
                    <v-icon>picture_as_pdf</v-icon>
                  </v-btn>
                  {{ fileName }}
                </div>
                <div class="flex xs12 sm12 md12">
                  <v-btn
                    size="small"
                    color="pink-lighten-1"
                    theme="dark"
                    :disabled="sending"
                    @click="fileSend"
                  >
                    はい
                    <v-icon end>
                      mdi-send
                    </v-icon>
                  </v-btn>
                  <v-btn
                    size="small"
                    color="lighten-1"
                    :disabled="sending"
                    @click="fileSendCancel"
                  >
                    いいえ
                    <v-icon end>
                      mdi-close-circle
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div id="chat-input">
            <v-container
              fluid
              class="pa-0"
            >
              <v-row
                class="ma-0 grey-lighten-2"
                style="background-color: #e0e0e0;"
              >
                <v-col
                  sm="10"
                  md="10"
                  class="pa-1 pl-0"
                >
                  <v-btn
                    v-if="logType=='karte'"
                    color="pink-lighten-1"
                    class="mr-0 pr-2 pl-2"
                    size="small"
                    theme="dark"
                    flat
                    :disabled="messageDisabled"
                    @click="requestSurvey"
                  >
                    アンケート
                  </v-btn>
                  <v-btn
                    v-if="logType=='karte'"
                    color="pink-lighten-1"
                    class="mr-0 pr-2 pl-2"
                    size="small"
                    theme="dark"
                    flat
                    :disabled="messageDisabled"
                    @click="inviteCalling"
                  >
                    通話誘導
                  </v-btn>
                  <v-btn
                    color="pink-lighten-1"
                    class="mr-0 pr-2 pl-2"
                    size="small"
                    theme="dark"
                    flat
                    :disabled="messageDisabled"
                    @click="sendEPDS"
                  >
                    EPDS
                  </v-btn>
                </v-col>
                <v-col
                  sm="2"
                  md="2"
                  class="pa-1 pr-0"
                >
                  <v-btn
                    class="ml-0"
                    size="small"
                    icon
                    max-height="25px"
                    variant="text"
                    :disabled="messageDisabled"
                    @click="pickFile"
                  >
                    <v-icon icon="mdi-paperclip" />
                  </v-btn>
                  <input
                    ref="file"
                    type="file"
                    style="display: none"
                    accept="image/*,application/pdf"
                    @change="onFilePicked"
                  >
                </v-col>
              </v-row>
            </v-container>
            <div class="xs12 sm12 md12">
              <v-textarea
                id="message_input"
                v-model="sendMessage"
                auto-grow
                variant="solo"
                rows="2"
                color="pink"
                placeholder="message here"
                style="font-size: 14px"
                hide-details
                :flat="true"
                :background-color="messageDisabled ? 'grey-lighten-4' : 'white'"
                :disabled="messageDisabled"
                @keydown.enter="send"
                @compositionstart="composing=true"
                @compositionend="composing=false"
                @keyup="calcInputHeight"
                @click="markAsRead"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const context = new AudioContext();
import { mapGetters, mapActions } from "vuex";
import timestampMixin from "../../mixins/timestamp.js";
import audioMixin from "../../mixins/audio.js";

export default {
  name: "Chat",
  mixins: [timestampMixin, audioMixin],
  props: {
    chatId: {
      type: Number,
      required: true
    },
    logType: {
      type: String,
      required: true
    },
    messageDisabled: {
      type: Boolean,
      required: true
    },
    departmentType: {
      type: Number,
      required: true
    },
    couponName: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      sendMessage: "",
      messageChannel: null,
      composing: false,
      fileName: "",
      fileUrl: "",
      fileType: "",
      showChatView: true,
      chatMessageStyle: "",
      unread: false,
      scrollHeight: 0,
      oldsScrollHeight: 0,
      calcScrollHeightIntervalId: null,
      sending: false,
      baseHeaderHeight: 0,
      savedInputHeight: 0,
    };
  },

  computed: {
    ...mapGetters([
      "currentDoctor",
      "chatLogs",
      "hasUnreadMessages",
      "latestMessageId",
      "room",
      "user",
      "epdsUrl"
    ]),
  },

  watch: {
    "$route" () {
      this.initialize();
      this.clearChatInput();
      // 新着通知のリセット
      this.unread = false;
    },
    scrollHeight (val){
      if( this.oldScrollHeight < val ){
        this.scrollToEnd();
      }
      this.oldScrollHeight = this.scrollHeight;
    },
    logType: {
      handler: "initTopHeight",
      immediate: true
    },
    departmentType: {
      handler: "loadEpdsUrl",
      immediate: true
    },
  },

  created() {
    this.initialize();
  },

  mounted () {
    this.calcInputHeight();
    this.calcScrollHeightIntervalId = setInterval(this.calcScrollHeight, 200);
  },

  beforeUnmount () {
    clearInterval(this.calcScrollHeightIntervalId);
    this.disConnectionChannel();
  },

  methods: {
    ...mapActions(["markAsRead"]),
    initialize(){
      this.disConnectionChannel();
      this.connectionChannel();
      this.loadChatLogs(true);
    },
    calcScrollHeight() {
      var scrollComponent = document.getElementById("chat-messages");
      if( scrollComponent != null ){
        this.scrollHeight = scrollComponent.scrollHeight;
      }
    },
    connectionChannel() {
      console.log(this.timestamp() + " connection Message Channel.");
      var that = this;
      this.messageChannel = this.$cable.subscriptions.create(
        {
          channel: "ChatMessageChannel",
          chat_id: this.chatId,
          log_type: this.logType,

        },
        {
          received: (data) =>  {
            console.log(that.timestamp() + " received karte message.");
            var log = JSON.parse(data["chat_log"]);
            log["logType"] = this.logType;
            that.$store.dispatch("addChatLog", log).then(()=>{
              if( log.readAt == null ){ // not myself
                if(that.windowIsNotActive()){
                  that.soundNotify();
                }
                that.unread = true;
              }
            });
          },
          connected: function() {
            console.log(that.timestamp() + " connected message channel.");
            that.loadChatLogs(false);
          },
          disconnected: function() {
            console.log(that.timestamp() + " disconnected message channel.");
          },
          rejected: function() {
            console.log(that.timestamp() + " rejected message channel.");
          },
        }
      );
    },
    disConnectionChannel() {
      if(this.messageChannel == null) return;
      console.log(this.timestamp() + " disconnection Message Channel.");
      this.messageChannel.unsubscribe();
    },
    send(e) {
      if (!this.composing && !this.sending) {
        // Alt + Enter/ Cmd + Enter の場合のみ送信する
        if(e.altKey === true || e.metaKey === true){
          e.preventDefault();
          if(this.sendMessage.replace(/\s/g, "").length > 0){
            this.sending = true;
            this.$store.dispatch("post", {
              chatId: this.chatId,
              content: this.sendMessage,
              logType: this.logType,
              contentType: "text",
            }).then(()=>{
              // 送信成功時の処理
              this.sendMessage = "";

              // DOMの高さ変更描画完了を待つ
              this.lazyCalcInputHeight();

              this.sending = false;
              // 長い文を送った場合ログが更新されないことがあるため
              this.loadChatLogs(false);
            }).catch(() => {
              // 送信失敗時の処理
              this.sending = false;
              // ユーザーが退会している可能性があるのでログを再取得する
              this.loadChatLogs(false);
            });
          }
        }
      }
    },
    sendByOthers(senderType){
      return senderType == "user";
    },
    pickFile(){
      this.clearFile();
      this.$refs.file.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if(files[0] !== undefined) {
        this.fileName = files[0].name;
        this.fileType = files[0].type;
        if(this.fileName.lastIndexOf(".") <= 0) {
          // ファイルじゃない
          return;
        }
        const fr = new FileReader ();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("loadend", () => {
          this.fileUrl = fr.result; // base64
        });
      }
    },
    fileSend(){
      this.sending = true;
      this.$store.dispatch("post", {
        chatId: this.chatId,
        fileUrl: this.fileUrl,
        fileName: this.fileName,
        contentType: this.imageFile(this.fileType) ? "image" : "pdf",
        logType: this.logType,
      }).then(()=>{
        // 送信成功時の処理
        this.clearFile();
        this.sending = false;
      }).catch(() => {
        // 送信失敗時の処理
        this.sending = false;
      });
    },
    fileSendCancel(){
      this.clearFile();
    },
    clearFile(){
      this.fileUrl = "";
      this.fileName = "";
      this.fileType = "";
    },
    calcInputHeight(){
      var inputHeight = document.getElementById("chat-input").clientHeight;
      if(this.savedInputHeight != inputHeight){
        this.savedInputHeight = inputHeight;
      }
    },
    lazyCalcInputHeight() {
      // DOMの描画完了を待つ
      this.$nextTick(function(){
        // DOMの高さ変更を待つ(v-textareaはnextTickを使って高さを変更しているため)
        this.$nextTick(function(){
          //this.calcInputHeight();
          setTimeout(()=> {
            this.calcInputHeight();
          }, 1500);
        });
      });
    },
    scrollToEnd(){
      var scrollArea = document.getElementById("chat-messages");
      scrollArea.scrollTop = scrollArea.scrollHeight;
    },
    markAsRead(){
      this.$store.dispatch("markAsRead", {
        chatId: this.chatId,
        logType: this.logType
      });
      this.unread = false;
    },
    loadChatLogs(beforeClear){
      if(beforeClear){
        this.$store.dispatch("clearChatLogs");
      }
      var savedLatestId = this.latestMessageId;

      this.$store.dispatch("fetchChatLogs", {
        chatId: this.chatId,
        logType: this.logType
      }).then(()=>{
        var hasNewMessage = this.latestMessageId != savedLatestId;
        if(this.unread && hasNewMessage && this.windowIsNotActive()){
          this.soundNotify();
        }
      });
    },
    loadEpdsUrl() {
      if(this.departmentType != null){
        this.$store.dispatch("fetchEpdsUrl", {
          department_type: this.departmentType
        });
      }
    },
    inviteCalling(){
      var fixedPhrase = "通話での相談をご希望ですね。\n";
      fixedPhrase += "下記のリンクをクリックすると通話を開始できます。\n";
      fixedPhrase += "クリックしていただけますか？\n";
      fixedPhrase += this.room["lineUrl"];
      fixedPhrase += "\n";
      fixedPhrase += "※ビデオ通話をご希望の場合、通話開始後、カメラマークをクリックして、ビデオをONにしてください。\n";
      fixedPhrase += "※音声通話をご希望の場合は、URLをクリックするだけで開始できます。";
      this.sendMessage = fixedPhrase;
      this.lazyCalcInputHeight();
    },
    requestSurvey(){
      var fixedPhrase = "産婦人科・小児科オンラインのサービスをご利用いただきありがとうございました。\n明日、簡単なアンケートをメールでお送りさせていただきます。その後の様子をお伺いできれば私たちも安心ですし、今後のサービス改善にもつなげていきたいので、ぜひご協力をお願いいたします。\nまたいつでもご利用ください。どうぞお大事にしてくださいね。";
      this.sendMessage = fixedPhrase;
      this.lazyCalcInputHeight();
    },
    sendEPDS(){
      var fixedPhrase = "産後はどの時期も体と心の疲労が溜まりやすい時期ですので、みなさんにアンケートとしてお願いしております。今のご自身の気持ちを振り返ってみましょう。\n";
      fixedPhrase += "ぜひ下記URLより回答してみてくださいね。\n\n";
      fixedPhrase += this.epdsUrl;
      this.sendMessage = fixedPhrase;
      this.lazyCalcInputHeight();
    },
    imageFile(contentType){
      return contentType.match(/image\/.*/);
    },
    pdfFile(contentType){
      return contentType.match(/application\/pdf/);
    },
    windowIsNotActive(){
      return !document.hasFocus() || document.activeElement.getAttribute("id") != "message_input";
    },
    newLineCodeToBr(str){
      return str.replace(/\r?\n/g, "<br>");
    },
    // for debug
    timestamp(){
      var now = new Date();
      return now.getFullYear() + "/" + (now.getMonth()+1) + "/" + now.getDate() + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
    },
    clearChatInput(){
      this.sendMessage = "";
    },
    initTopHeight(){
      if(this.logType == "daytimeAdvice"){
        this.baseHeaderHeight = 170;
      }
      if(this.logType == "karte"){
        this.baseHeaderHeight = 120;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#chat-view {
  height: 90vh;
}

.chat-view-modern {
  padding: 0;
  padding-bottom: 20px;
  opacity: 1;
  box-sizing: border-box;
  z-index: 100001;
  max-height: 100%;

  width: auto;
  color: #424242;
}

#chat.outline {
  background: #fff;
  border: 1px solid #e0e0e0;
}

#chat-header {
  position: relative;
  z-index: 100;
  min-height: 40px;
  width: 100%;
  padding: 0 16px;
  border-bottom: 1px solid #e0e0e0;
  color: #757575;
}

.new-notice {
  animation: notice-color 1s infinite;
  -webkit-animation: notice-color 1s infinite;
}

.chat-title {
  font-size: 14px;
  line-height: 20px;
  min-height: 40px;
  margin-right: 60px;
  overflow-x: hidden;
  width: 100%;
  padding: 10px 4px;
}

#chat-body {
  padding-bottom: 0px;
  height: 100%;
}

#chat-messages {
  overscroll-behavior-y: contain;
  overflow: scroll;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 5px
}

.chat-log {
  clear: both;
  padding-top: 5px;
  width: 100%;
  overflow: hidden;

  /* flex box */
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;

  .message-area {
    padding: 15px;
    border: 1px solid;
    margin-bottom: 5px;
    max-width: 250px;

  }

  .sent-at {
    color: #999;
    font-size: 10px;
  }

  .confirm {
    max-width: fit-content;
  }
}

.others {
  padding-right: 30px;
  flex-direction: row;

  .message-area {
    border-color: #e0e0e0;
    border-radius: 0 20px 20px 20px;
    padding-left: 10px;
  }
}

.myself {
  padding-left: 30px;
  flex-direction: row-reverse;

  .message-area {
    border-color: #FFD1E0;
    background-color: #FFD1E0;
    border-radius: 20px 0 20px 20px;
    padding-right: 10px;
  }

  .sent-at {
    text-align: right;
  }
}

#chat-input {
  background-color: #fff;
}

.filled-image {
  width: 200px;
  height: auto;
}

@-webkit-keyframes notice-color {
  0%   { background-color: #fad1df; color: #757575;}
  20%  { background-color: #f6a2be; color: #999;}
  40%  { background-color: #ef5d8d; color: #CCC;}
  60%  { background-color: #ec407a; color: #FFFFFF;}
  80%  { background-color: #f48bae; color: #CCC;}
  100% { background-color: #f8b9ce; color: #757575;}
}
@keyframes notice-color {
  0%   { background-color: #fad1df; color: #757575;}
  20%  { background-color: #f6a2be; color: #999;}
  40%  { background-color: #ef5d8d; color: #CCC;}
  60%  { background-color: #ec407a; color: #FFFFFF;}
  80%  { background-color: #f48bae; color: #CCC;}
  100% { background-color: #f8b9ce; color: #757575;}
}
</style>
